/* You can add global styles to this file, and also import other style files */

/* 



CSS CONTENTS

1.HOME-PAGE 
2.TV-Programs
3.DONATE
4.PRAYER-REQUEST
5.WATCH 


*/
@import "~@ngx-share/button/styles/share-buttons.css";
@import "~@ngx-share/button/styles/themes/material/material-dark-theme.css";
@import "~@ngx-share/button/styles/themes/circles/circles-dark-theme.css";
@import "~@ngx-share/button/styles/themes/circles/circles-light-theme.css";
@import "~@ngx-share/button/styles/themes/modern/modern-light-theme.css";
@import "~@ngx-share/button/styles/themes/outline/outline-theme.css";
html,
body {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;

  font-family: "Inter";
  font-style: normal;
  box-sizing: border-box !important;
}
::ng-deep .iti input,
.iti input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 27px !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.fixed-top {
  position: fixed;
  top: -1px;
  right: 0;
  left: 0;
  z-index: 1030;
}
.btn .nav-link button[type="button"] .tv-1 {
  cursor: pointer;
}
.fa-whatsapp {
  color: #ffffff !important;
  margin-top: 10px;
  margin-right: 2px;
}
/* navbar-css  */
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: none !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("../src/assets/images/toggle.svg");
}
/* navbar-css  */
.mat-button-focus-overlay {
  background-color: transparent !important;
}

.cursor {
  cursor: pointer;
}

.invalid-feedback {
  color: red !important;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter";
}

.main {
  overflow: hidden !important;
}
*:focus,
button.focus,
button:focus,
.btn.focus,
.btn:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:hover {
  color: none !important;
}

*,
*:focus,
*:hover {
  box-shadow: none;
  outline: none;
}
/* navbar css   */
.nav-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #2b3244 !important;
}
.header-logo {
  max-width: 100%;
}

.request-prayer .btn,
.request-prayer-1 .btn {
  background: #3787ff;
  mix-blend-mode: normal;
  align-items: center;
  padding: 10px 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  border-radius: 53px;
}

.give-god .btn {
  background: #491a40;
  border-radius: 53px;
  border: 0.5px solid #e1e4e8;
  align-items: center;
  padding: 10px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}
.dropdown-toggle {
  background: #ffffff;
}
.header-name {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 21.9821px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #000000;
}
/* 
.main-header {
  background: #491a40;
  padding: 17px 13px;
  margin: 87px 0 0 0;
} */
.main-header {
  background: #ffffff;
  padding: 17px 13px;
  margin: 57px 0 0 0;
}
.main-header-section h4 {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 0px 18px;
  margin: 0;
}
.main-header-section .btn {
  padding: 0;
}
.main-header-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  line-height: 25px;
  color: #fd6a65;
  background: #ffecec;
  border-radius: 12px;
  padding: 0px 12px;
  margin: 0px 0 0 5px;
}
/* navbar css   */

/* faq  css  */

.faq-header h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 61px;
  letter-spacing: 0.325px;
  color: #2b3244;
}

mat-panel-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2b3244;
}
mat-expansion-panel p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a657f;
}

.q-m {
  height: 75px;
  width: 115px;
}
/* faq  css  */

/* about-us css  */
.about-us-header-section {
  background-color: #fff4f8;
}
.about-us-image {
  max-width: 100%;
  height: 100%;
  margin-top: 17%;
  margin-left: 5%;
}
.about-us-header {
  margin-top: 20%;
}
.about-us-header h3 {
  font-weight: 800;
  font-family: "Red Hat Display";
  font-size: 64px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #fdc332;
}
.about-us-header h4 {
  font-family: "Red Hat Display";
  font-weight: 800;
  font-size: 96px;
  line-height: 85%;
  display: flex;
  align-items: center;
  color: #491a40;
}

.about-us-title h4 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
  color: #ff7474;
}
.about-us-title h5 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 45px;
  color: #0b0076;
}
.about-us-title p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #77779f;
}
.submit-button button {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #3787ff;
  padding: 10px 44px;
}
.title-img {
  height: 100%;
  max-width: 160%;
}
.subscribe-section {
  /* background: #491a40 !important; */
  border-radius: 20px;
  margin-top: 5%;
  height: 250px;
}
.fa-whatsapp {
  color: #0dbc67;
  margin-top: 10px;
  margin-right: 2px;
}
.fa-whatsapp:before {
  content: "\f232";
  font-size: 20px;
}
.subscribe-title {
  padding: 29px;
}
.subscribe-title h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fafbfd;
}
.subscribe-title h4 {
  font-family: "Red Hat Display";
  font-weight: 700;
  font-size: 34px;

  text-align: center;
  color: #ffffff;
}
.form-section {
  justify-content: center;
  display: flex;
}

.subscribe-form-section {
  background-color: #ffffff;
  padding: 8px 19px 8px 34px;
  /* margin: 0% 28% 0% 32%; */
  border-radius: 58px;
  width: 435px;
  margin: auto;
}
.subscribe-form-section input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #75809c;
  background-color: #ffffff;
  border: none;
}
.btn-subscribe {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background: #3787ff;
  border-radius: 0px 32px 32px 0px;
  color: #ffffff;
  padding: 10px 13px;
  margin-left: 5px;
}

/* about-us css  */

/* home page css    */
.largest-chruch-section {
  position: relative;
  text-align: center;
  color: white;
}
.largest-tv-section p {
  display: none;
}
.p-circle-img {
  width: 17px;
  height: 17px;
  margin: -1px 0 0 0;
}
/* .join-chruch-section {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.prayers {
  max-width: 100%;
  /* background-image: url("./assets/images/home-welcome-image.png"); */
  background-image: url("./assets/images/logo-website.webp");
  background-size: cover;
  position: relative;
  height: 101vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
}
.welcome_chruch {
  margin: 0 auto;
  text-align: center;
  padding-top: 31%;
}
.welcome_chruch h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 66px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0 auto;
  width: 54%;
  padding: 10px 0;
  background-color: #000000;
}
.welcome_chruch p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin: 26px;
}
.join-btn-section .btn {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 16.8px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  background: #3787ff;
  border-radius: 68px;
  background: #3787ff;
  border-radius: 68px;
  width: 18%;
  padding: 9px 10px;
}
.image-god {
  width: 150%;
}
.man-of-god-section {
  background: #030303;
}
.man-of-god-btn-section {
  display: flex;
}
.founder-details-section h1 {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #d5ddf3;
  margin: 4px 0;
}
.founder-details-section h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 111.5%;
  /* identical to box height, or 39px */

  letter-spacing: 0.015em;
  text-transform: uppercase;
  margin: 0;
  color: #ffc60d;
}

.founder-details-section h5 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 111.5%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0px 0px 8px 0;
}
.founder-details-section h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  text-transform: uppercase;
  color: #d5ddf3;
  margin: 0;
}
.founder-details-section p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #d5ddf3;
  text-align: left;
  margin: 25px 0;
  width: 98%;
  word-wrap: break-word;
  word-break: break-word;
}
.founder-details-section {
  margin: 79px 0px 0px 6px;
}
/* .founder-details-section {
  margin: 79px 0px 0px -103px;
} */

.watch-btn .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 13px 54px;
  margin: 17px 0px;
}
.btn-watch-live {
  background: #3787ff;
  border-radius: 35px;
}
.btn-Schedule {
  border: 0.5px solid #e1e7f7;
  border-radius: 35px;
  background: transparent;
  padding: 15px 60px !important;
  /* width: 100%; */
}

.chruch-buttons-section,
.alternative_payments_btn {
  display: flex;
}
.alternative_payments_btn_1 {
  display: none;
}
.msg_btns_2,
.chruch_btn_2 {
  display: flex;
}
.message_week h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-transform: uppercase;
  color: #fd6a65;

  margin: 14px 0;
}
.message_week h5 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 103.5%;
  letter-spacing: -0.02em;
  color: #ffffff;

  margin: 13px 0;
}
.message_week p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #ffffff;
  margin: 19px 0 24px 0px;
}

.chruch-timing-main-section {
  background-color: #fafbfd;
}
.week-msg-section {
  /* background: #491a40; */
  background: linear-gradient(
    to right,
    #491a40 0%,
    #491a40 50%,
    #491a40 50%,
    #664e4e 100%
  );
}
.video-tag {
  max-width: 100%;
  max-height: 103%;
  margin: 68px 0 0 55px;
}
.this-week-message-section {
  padding: 100px 0;
}
.message_week {
  margin-top: 56px;
}
.timer-image {
  max-width: 100%;
  height: 100%;
}
.chruch-header h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  /* identical to box height, or 48px */

  letter-spacing: 0.325px;

  /* Grays / 100 */

  color: #2b3244;
}
.chruch-temple-section h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 108%;
  color: #17161a;
  /* width: 50%; */
  justify-content: center;
  margin: 14px 0 28px 11px;
}
.family-section h4 {
  display: none;
}
#church {
  max-width: 100%;
  max-height: 100%;
}
.family-section h1 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 99.5%;
  color: #17161a;
  margin: 32px 0 0 0;
}
.family-section h2 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 99.5%;
  margin: 5px 0 32px 0;
  /* opacity: 0.5; */
  color: #491a40;
}
.home-page-hr {
  opacity: 0.5;
  border: 0.5px solid #491a40;
  width: 91%;
  margin: 29px 0px;
}
.outreach-section h3 {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 108%;
  color: #491a40;
  margin: 0px 0px 0px 12px;
}
.tv-outreach-section h5 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */
  margin: 11px 0;

  /* ColorDark */

  color: #17161a;
}
.f-l {
  background: #f9f9f9;
  margin-left: -12px;
}
#reach-out {
  max-width: 100%;
  height: 28px;
  margin: 10px 0;
}
.family-section {
  margin-top: 105px;
  margin-left: 44px;
}
.about-chruch-home {
  margin: 100px 0;
}
.tv-outreach-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #232226;
  width: 76%;
}
.ministry-section h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: 0.325px;
  color: #491a40;
}
#tv-1 {
  max-width: 100%;
}
#tv-2 {
  display: none;
}
.tv-program h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 8%;
  letter-spacing: 0.325px;
  color: #fa7a78;
}
.btn-tv-sponsor .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;

  background: #491a40;
  padding: 13px 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
  border-radius: 68px;
}
.btn-tv-View {
  border: 0.5px solid #491a40;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
  border-radius: 68px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #491a40;
  padding: 13px 16px;
  background: transparent;
}

.tv-sponsor {
  margin: 13px 4px;
}
.tv-programs-img {
  margin: -24% -32% 0% -8%;
}
.tv-program h4 span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  /* line-height: 100%; */
  /* identical to box height, or 48px */

  letter-spacing: -1px;

  color: #424b62;
}
.tv-program h5 span {
  color: #000000;
}
.tv__ministry {
  background-color: #fff6f6;
}
.tv-program h5 {
  color: #fa7a78;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 100%;
  letter-spacing: 0.325px;
  /* margin-top: 33px; */
  width: 100%;
}
.tv-program p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #75809c;
  margin: 18px 0;
}
.ministry-section img {
  max-width: 100%;
  height: 51px;
  margin-right: 10px;
}
.weekly-timing-dates_1 {
  background-color: #491a40;
}
.weekly-timing-dates-services-1 {
  background: #fafbfd !important;
}
.weekly-timing-dates-services-2 {
  background: #edf1fa !important;
}

.weekly-timing-dates h6,
.weekly-timing-dates_1 h6,
.weekly-timing-dates-services-1 h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #fd6a65;
  margin: 0;
}
.weekly-timing-dates h4,
.weekly-timing-dates-services-1 h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #2b3244;
  margin: 0;
}
.weekly-timing-dates_1 h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
  color: #fafbfd !important;
}
.helping-the-poor-section {
  margin: 60px 0 32px;
}
.helping-the-poor-section h3 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.325px;
  color: #000000;
  width: 52%;
  justify-content: center;
  margin: 0 auto;
}

.subscribe_social_section h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 108%;
  font-feature-settings: "salt" on, "liga" off;
  color: #ffffff;
  text-align: center;
  margin: 10px;
}

.subscribe_section {
  background-color: #491a40;
  padding: 24px 0px;
}
.subscribe_social_section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */
  margin: 10px;
  text-align: center;
  font-feature-settings: "salt" on, "liga" off;

  color: #fafbfd;
}

/* .social_media_icons i {
   border: 1.21053px solid #FAFBFD;
    border-radius: 72.6316px;
    font-size: 25px;
     
  padding: 20px;
  justify-content: center;
  color: white;
  transition: all 0.3s ease;
  width: 80px;
  margin: 0px 23px;
  height: 80px;
  border-radius: 80px;
  font-size: 37px;
  border: 2px solid #ebebeb;
  cursor: pointer;
} */

.testimonies_section_main p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 167% */

  text-align: center;

  /* Grays / 70 */

  color: #75809c;
}
.testimonies_header {
  width: 100% !important;
  height: 100%;
  background: #ffffff;
  border: 0.5px solid #edf1fa;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
  border-radius: 15px;
}
.read_more-btn {
  text-align: center;
}
.inspired_main {
  margin-top: 48px;
}
.main_testimonties-header {
  background: #fff6f6;
}
.inspired_testimonties {
  padding: 52px 0 0px 0;
  margin-bottom: -10%;
}
.inspired_testimonties p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.325px;
  color: #2b3244;
  margin: 7px 0 0 0;
}
.testimonies_section h5 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #2b3244;
  margin: 18px 5px;
}
.read_more-btn .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  background: #3787ff;
  /* Ghost__Shadow */
  padding: 10px 14px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
  border-radius: 26px;
  width: 65%;

  margin-top: 14px;
}

.btn-video {
  border-radius: 82px;
  padding: 10px 22px !important;
  margin: 17px 19px 0 0 !important;
}
.timing-section-btns {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 10px 25px;
  margin: 17px 20px;
  width: 89%;
}
.btn-watch {
  background: #fa7a78 !important;
  /* Ghost__Shadow */
  width: 100%;
  color: #fafbfd;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
}
.watch-live-btn .btn {
  background: #491a40;
  color: #fafbfd;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
  border-radius: 49px;
}
.btn-direction,
.btn-share {
  color: #2b3244;
  background: #ffffff;
  border: 0.5px solid #2b3244;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
  border-radius: 49px;
}
.btn-direction-1 .btn,
.btn-share-1 .btn {
  color: #ffffff;
  background: transparent;
  border: 0.5px solid #ffffff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
  border-radius: 49px;
}

/*** 

====================================================================
                       Home-css-END
====================================================================

***/

/*** 

====================================================================
                        Tv-Programs-css -START
====================================================================

***/

/* TV-progrms-main-section */
.largest-tv-section {
  text-align: center;
  padding: 135px 0 0px;
}
.largest-chruch-section-1 {
  max-width: 100%;
  /* background: linear-gradient( 0deg, rgba(73, 26, 64, 0.58), rgba(73, 26, 64, 0.58) ), url(l-1.png); */
  background-size: cover;
  position: relative;
  background: #491a40;
  /* height: 73vh; */
  height: 520px;
  background-blend-mode: multiply, normal;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
  border-top: 2px solid white;
}
.largest-tv-section h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  justify-content: center;
  margin: 0 auto;
}
.program-btn-section {
  margin-top: 77px;
}
.program_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  border: none;
  padding: 10px;
  text-align: center;
  background: #fdc332;
  color: #ffffff;
  border-radius: 0;
}

/* table-content-css */
.table-channles-icons_img {
  border-radius: 50%;
  margin-left: 27px;
  margin-right: 15px;
  width: 38px;
  height: 38px;
  margin-top: -6px;
}

/* form-section */
.tv-programs-sponsor-section h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #2b3244;
  margin: 5px 0px;
}
.tv-programs-sponsor-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  color: #75809c;
  margin: 5px 0;
}
.tv-programs-sponsor-section {
  margin: 67px 65px;
}
.proceed {
  padding: 13px 75px !important;
}
/* .back_to_btn {
  width: 100% !important;
  padding: 10px 81px !important;
  margin: 12px 0 !important;
} */
/* payment-gateway css */
.back-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2b3244;
  background-color: #ffffff;
  margin: 10px 0;
}
.payment_selection_name h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  /* Grays / 100 */

  color: #2b3244;
}
.payment_bank_name h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  color: #75809c;

  margin: 18px 0px 7px 0px;
}

.payment_bank_name h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2b3244;
}
.scan_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background: #e7f4ff;
  border: 0.3px solid #66b8ff;
  border-radius: 31px;
  color: #0074d6;
  padding: 10px 17px;
  margin: 25px 0;
}
.sbi_img {
  width: 59px;
  height: 59px;
  margin: 9px 5px;
}
.net_banking_selection h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #000000;

  margin: 17px 4px 2px 0;
}
.net_banking_selection h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #75809c;
}
.upi {
  width: 21px;
  height: 30px;
  margin: 15px 15px 0 0;
}
.phone_pe_icons {
  width: 40px;
  height: 40px;
}

.online_payment-btns h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #5a657f;
  margin: 11px 0px 0px 15px;
}
#upi_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #878dba;

  margin: 26px 0 0 21px;
}
/* modal css */
.congrats {
  justify-content: center;
  margin: 20px auto;
  text-align: center;
}
.success_Img {
  width: 150px;
}
.greeting_card_details {
  padding: 48px 0 0 36px;
  width: 42%;
}
.greeting_card_details_main {
  background-size: cover;
  position: relative;
  height: 30vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
  max-width: 100%;
  border-radius: 20px;
}
.thank_you-section h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 0px 0 10px;
}
.thank_you-section span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  display: block;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 0px 0 10px;
}
.thank_you-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0px 0 7px;
  color: #75809c;
}
.thank_you-section h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  background: #fff3e5;
  border: 0.3px solid #ffb65c;
  border-radius: 31px;
  color: #ff8d00;

  padding: 10px;
  margin: auto;
  width: 60%;

  padding: 10px;
}

.arr_left {
  max-width: 100%;
  margin: -2px 0 0 0;
}
.greeting_card_details h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: left;
}

.greeting_card_details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  text-align: left;
  margin: 6px 8px 6px 1px;
}
.greeting_card_btn .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fafbfd;
  border: 0.5px solid #e1e7f7;
  border-radius: 23px;
  background: transparent;
  margin: 9px 0 0 0px;
}
.greeting_card {
  max-width: 100%;
}
/*** 

====================================================================
                        Tv-Programs-CSS -END
====================================================================

***/

/***


====================================================================
                        DONATE-CSS-START
====================================================================

***/

/* scan qr section css  */

.scan_qr_details h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  text-align: left;
  margin: 100px 0 30px 0;
  color: #0b0076;
  width: 92%;
}
.another_payment_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  border-radius: 26px;
  text-align: center;
  border-radius: 26px;
  margin: 7px 11px 0 0;
}
.qr_img {
  max-width: 100%;
}
.alternative_payments_btn .btn {
  background: #3787ff;
  color: #ffffff;
  padding: 13px 16px;
}
.btn_learn {
  color: #2b3244 !important;
  border: 0.5px solid #2b3244;
  padding: 14px 53px !important;
  background: transparent !important;
}
.donate_img {
  width: 100%;
}
.select_donation_section {
  background-image: url("./assets/images/Donate.webp");
  background-size: cover;
  position: relative;
  /* height: 101vh; */
  height: 740px;
  max-width: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
}
.payment_select_cards_section {
  margin: 0;
}
.payment_section_details {
  position: absolute;
  top: 13%;
  left: 69%;
  transform: translate(-50%, -50%);
}
.payment_select_cards_section {
  position: absolute;
  top: 49%;
  left: 71%;
  transform: translate(-50%, -50%);
}
.payment_section_details h3 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 68px;
  color: #491a40;
  /* width: 35%;
    margin: 0 188px 0px;
    float: right; */
}
.payment_section_details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin: 17px 0 14px 0;
  text-align: left;

  color: #2b3244;
}
.card_donate h5 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 37px;
  text-align: left;
  color: #491a40;
  /* width: auto;
    margin: 0;
    float:none; */
}
.select_payment_option h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Grays / 100 */
  margin: 0;

  color: #2b3244;
}
.btn-pray .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  width: 100%;
  padding: 15px;
  text-align: center;
  background: #3787ff;
  border-radius: 26px;

  color: #ffffff;
}

.sponsor-header h5 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #004258;
}
/***


====================================================================
                        DONATE-CSS-END
====================================================================

***/
/***


====================================================================
                        PRAYER-REQUEST-CSS-START
====================================================================

***/

/* MAIN SECTION */
.pray_request_header_request {
  padding: 132px 0;
  margin-left: -6%;
}
.t_1 {
  width: 37px;
  height: 37px;
}
.details_1 {
  margin: 14px 1px !important;
}
.pray_request_header_request h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  width: 50%;
}
.prayer_request_section {
  background-image: url("./assets/images/pray_1.webp");
  background-size: cover;
  position: relative;
  height: 41vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
  max-width: 100%;
}
.problem_selection_menu_section {
  margin: 100px 0;
}
.prayer_tower_section h4 {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
  font-feature-settings: "salt" on, "liga" off;
  color: #18191f;
  text-align: center;
}
.language_details {
  width: 100%;
}
.telugu_prayer_tower {
  background: #ffffff;
  padding: 7px 11px 7px 7px;
  margin: 13px 0;
  border: 0.5px solid #d5ddf3;
  border-radius: 10px;
}

/* .line-hor {
  border: 0.5px solid #B3BDD6;
transform: rotate(90deg);
} */
.prayer_languges h4,
.prayer_languges h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 20px */
  font-family: "Plus Jakarta Sans";
  text-align: center;
  text-transform: capitalize;

  /* Neutral/100 */

  color: #171c22;
}
.border_right {
  border-right: 0.5px solid #b3bdd6;
}

/* cards-request-prayer */
.card_img_request {
  max-width: 100%;
  /* width: 100%; */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 129px;
}
.marriage_option h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #1a264e;
}
.problem_selection h4 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.325px;

  color: #0e1828;
}
.pray_request_btns .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  background: #3787ff;
  border-radius: 31px;
  color: #ffffff;
}
/* cards-request-prayer */

/* prayer-request_FORM_SECTION */
.prayer_request_name {
  margin: 35px 0;
}
.select-request select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background: transparent url("~src/assets/images/a-d.svg") no-repeat 100%
    center;
  padding-right: 25px;
}
.back h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  margin: 0;
  color: #2b3244;
  font-family: "Inter";
}
.prayer-request-header h4 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: #2b3244;
  margin: 0;
  font-family: "Inter";
}
.prayer-request-header h4 span {
  color: #3787ff;
}
.prayer-request-header p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: "Inter";
  color: #75809c;
  margin-top: 3px;
}
.text-option {
  /* margin-bottom: 27px!important; */
  margin-top: 27px !important;
}
.navbar-request,
.prayer-laptop-screen {
  display: block;
}
.prayer-mobile-screen {
  display: none;
}

/* prayer-request */

/***


====================================================================
                        PRAYER-REQUEST-CSS-END
====================================================================

***/

/***


====================================================================
                        WATCH-CSS-START
====================================================================

***/
/* watch-section-Sliders css */
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.slick-prev:hover,
.slick-prev-1:hover,
.slick-prev-2:hover {
  background-image: url("../src/assets/images/left-2.svg");
  background-size: 29px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.slick-next:hover,
.slick-next-1:hover,
.slick-next-2:hover {
  opacity: 0.1;
  color: white;
  background-size: 29px;
  background-repeat: no-repeat;
}

.slick-prev {
  position: absolute;
  top: -128px;
  left: 84%;
  width: 30px;
  height: 30px;
  font-size: 5px;
  opacity: 1;
  /* transform: translate(0, 60%) !important; */
  z-index: 99999;
}
.slick-next {
  position: absolute;
  top: -128px;
  left: 93%;
  width: 30px;
  height: 30px;
  opacity: 1;
  z-index: 99999;
  /* transform: translate(0%, -68%) !important; */
}
.slick-prev-1 {
  position: absolute;
  top: -15%;
  left: 84%;
  width: 30px;
  height: 30px;
  font-size: 5px;
  opacity: 1;
  transform: translate(0, 60%) !important;
  z-index: 99999;
  cursor: pointer;
}
.slick-next-1 {
  position: absolute;
  top: -7%;
  left: 93%;
  width: 30px;
  height: 30px;
  opacity: 1;
  z-index: 99999;
  cursor: pointer;

  transform: translate(0%, -63%) !important;
}
.slick-prev-2 {
  position: absolute;
  top: -18%;
  left: 87%;
  width: 30px;
  height: 30px;
  font-size: 5px;
  opacity: 1;
  transform: translate(0, 60%) !important;
  z-index: 99999;
  cursor: pointer;
}
.slick-next-2 {
  position: absolute;
  top: -7%;
  left: 94%;
  width: 30px;
  height: 30px;
  opacity: 1;
  z-index: 99999;
  cursor: pointer;

  transform: translate(0%, -60%) !important;
}
.form-group label {
  display: none;
}
/* watch-section-Sliders css */
.watch_section_header {
  background-image: url("../src/assets/images/wathc.png");
  max-width: 100%;
  background-size: cover;
  position: relative;
  /* height: 73vh; */
  height: 580px;
  background-blend-mode: multiply, normal;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
}
.watch_page {
  position: absolute;
  top: 20%;
  left: 12%;
}
.home_to_watch_page ul li {
  margin: 0px 30px 0 0;
  list-style: none;
}
.home_to_watch_page ul li h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f4f6f8;
  cursor: pointer;

  margin: 0;
}
.watch_img {
  max-width: 100%;
}
.home_h2 {
  opacity: 0.6;
  cursor: pointer;
}
.home_to_watch_page h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;

  text-align: left;
  color: #ffffff;
}
.home_to_watch_page p {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  width: 54%;
  color: #edf1fa;
}

/* video-slider css   */
.recent_messages p {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  letter-spacing: 0.325px;
  margin: 11px 18px;
  color: #0e1828;
}
.video_header_tag {
  /* width: 100%;
  height: auto;
  background: #ffffff; */
  border: none;
}
.main_video_slider h4 {
  font: normal 600 14px/20px "Inter";
  color: #939eaa;

  margin: 22px 0px 4px 0px;
}
.main_video_slider h5 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #00385a;
  text-transform: uppercase;
  margin: 0;
}
iframe {
  border: none !important;
}
.main_video_slider h3 {
  font: normal 600 14px/20px "Inter";

  color: #75809c;
  margin: 3px 0px;
}
.main_video_slider p {
  font: normal 400 16px/25px "Inter";
  margin: 13px 0px;

  color: #939eaa;
}
.main_video_slider a {
  font: normal 500 14px/17px "Inter";
  margin: 13px 0px;
  color: #00a0ff;
}
.player_details h5 {
  font: normal 700 33px/50px "Plus Jakarta Sans";
  color: #2b3244;
  margin: 0 10px;
  text-transform: uppercase;
}
.details_Section_watch p {
  font: normal 600 20px/24px "Inter";

  margin: 0;
  color: #2b3244;
}
.details_Section_watch h5 {
  font: normal 400 16px/19px "Inter";
  color: #75809c;
  text-transform: none;
  margin: 7px 0;
}
.share_watch p {
  font: normal 600 16px/19px "Inter";
  margin: 6px -5px;
  color: #2b3244;
}

.back-span {
  display: none;
}

/***


====================================================================
                        WATCH-CSS-END
====================================================================

***/
/*

====================================================================
                      pagenotfound-css-start
====================================================================
*/
.pagenotfound-Details h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 158%;
  text-align: center;
  color: #2b3244;
  margin: 0;
}
.pagenotfound-Details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #75809c;
  width: 450px;
  margin: 0;
}
.pagenotfound-Details p a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #1091ff;
}
/*

====================================================================
                      pagenotfound-css-end
====================================================================
*/
/* media queries */

/* different techniques for iPad screening */

/* @media only screen and (max-width: 320px) {
  .subscribe-title h4 {
    font-size: 18px;
    line-height: 5px;
    letter-spacing: 0.1px;
  }
  .subscribe-form-section input {
    font-size: 10px;
  }
  .subscribe-form-section {
    width: 276px;
  }
  .btn-subscribe {
    font-size: 10px;
    padding: 6px 11px;
    margin-left: 0px;
  }
  .subscribe-section {
    height: 211px;
  }
} */

.web-view-tv-ministry {
  display: block;
}
.mobile-view-tv-ministry {
  display: none;
}

.Scan-Code {
  display: block;
}
.give-now-mobile {
  display: none;
  margin-right: -37%;
}
.give-now {
  display: initial;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .give-now-mobile {
    display: initial;
    margin-right: -37% !important;
  }
  .give-now {
    display: none;
  }
  .header-logo {
    max-width: 100%;
    height: 58px !important;
  }
  .logo-class {
    width: auto !important;
  }
  .pagenotfound-Details p {
    width: auto;
    font-weight: 400;
  }
  .pagenotfound-Details h5 {
    font-weight: 600;
    font-size: 29px;
  }
  .vertical-content ol {
    padding: 0 10px;
  }
  .phone .form-control {
    padding: 15px 49px !important;
  }
  /* footer-css */
  .sb-circles-dark .sb-wrapper .sb-icon {
    background-color: #2e4057;
    box-shadow: 0 3px 5px -1px rgb(0 0 0 / 0%), 0 6px 10px 0 rgb(0 0 0 / 0%),
      0 1px 18px 0 rgb(0 0 0 / 0%) !important;
    font-size: 1.4em;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
  }
  .thank_you {
    font: normal 500 12px/0px "Inter" !important;
    color: #fafbfd !important;
    margin: 33px 0 10px 0 !important;
    width: auto !important;
  }
  .click_here,
  .click_here a {
    font: normal 700 12px/20px "Inter" !important;
    color: #fafbfd !important;

    width: auto !important;
  }
  /* footer-css */
  /* slick-slider-watch-css */
  .slick-prev-1 {
    position: absolute;
    top: -12%;
    left: 78%;
    transform: translate(0, 0) !important;
  }
  .slick-next-1 {
    position: absolute;
    top: -6%;
    left: 90%;
  }
  .slick-prev {
    position: absolute;
    top: -114%;
    left: 79%;
  }
  .slick-next {
    position: absolute;
    top: -111%;
    left: 90%;
    transform: translate(0%, -76%) !important;
  }
  .slick-prev-2 {
    position: absolute;
    top: -18%;
    left: 78%;
  }
  .slick-next-2 {
    position: absolute;
    top: -7%;
    left: 90%;
  }
  .main_video_slider h5 {
    font-size: 15px;
    line-height: 21px;
  }
  /* slick-slider-watch-css */

  .main-header-section {
    display: none !important;
  }
  .home-page-hr {
    display: none;
  }
  .about-chruch-home {
    margin: 0px 0;
  }
  .main-header {
    background: #491a40;
    padding: 11px;
    margin: 59px 0 0 0;
  }
  .ministry-section {
    padding: 40px 0 12px;
  }
  /* Watch-css */
  .watch_section_header {
    background-image: url("../src/assets/images/w-2.png") !important;

    height: 580px;
  }
  .home_to_watch_page h4 {
    font-weight: 900;
    font-size: 32px;
    line-height: 110%;
    text-align: center;
  }
  .f-l {
    background: #f9f9f9;
    margin-left: 0;
  }
  .home_to_watch_page p {
    text-align: center;
    width: 73%;
    margin: 0 auto;
  }
  .home_to_watch_page ul {
    display: none !important;
  }
  .watch_page {
    top: 8%;
  }
  .recent_messages p {
    font-size: 16px;
    line-height: 20px;
  }
  /* Watch-css */
  /* home-page-css */
  .welcome_chruch h4 {
    font-size: 30px;
    width: auto;
  }
  .welcome_chruch p {
    font-size: 16px;
    line-height: 19px;

    margin: 23px;
  }
  .join-btn-section .btn {
    font-size: 15.8px;
    font-family: "Inter";
    width: 48%;
    padding: 7px 6px;

    font-weight: 400;
  }

  .welcome_chruch {
    margin: 0 auto;
    text-align: center;
    padding-top: 96%;
  }
  .prayers {
    height: 550px;

    /* background-image: url("./assets/images/about-1.png"); */
    background-image: url("./assets/images/logo-website.webp");
  }

  .man-of-god {
    /* margin: 50px 0; */
  }
  .week-msg-section {
    background: #491a40;
    /* margin-top: -16px; */
  }
  .founder-details-section {
    margin: 0;
  }
  .founder-details-section h1 {
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    margin: 51px 0 18px 0;
  }
  .founder-details-section h4 {
    font-size: 20px;
    text-align: center;
    line-height: 111.5%;
  }

  .founder-details-section h5 {
    font-size: 27px;
    text-align: center;
    line-height: 36px;
  }
  .founder-details-section h3 {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    font-weight: 500;
  }
  .founder-details-section p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 22px 32px;
    width: auto;
    color: #b3bdd6;
  }
  .watch-btn .btn {
    padding: 13px 54px;
    margin: 11px 24px;
    width: 89%;
  }

  .message_week {
    margin-top: 13px;
  }

  .chruch-temple-section h4 {
    display: none;
  }
  .family-section h4 {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #75809c;
  }
  .message_week h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 14px 0;
    text-align: center;
  }
  .chruch-buttons-section {
    display: block;
  }
  .alternative_payments_btn {
    display: none;
  }
  .alternative_payments_btn_1 {
    display: block;
  }
  .msg_btns_2,
  .chruch_btn_2 {
    display: flex;
  }

  .btn-watch {
    width: 97%;
    margin: 11px 13px 16px !important;
    background: #fd6a65 !important;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
  }
  .video-tag-1 {
    padding: 0;
    margin: 0 0 -4px;
  }

  .btn-direction,
  .btn-share {
    color: #2b3244;
    background: #ffffff;
    border: 0.5px solid #2b3244;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
    border-radius: 49px;
    width: 99%;
    margin: 3px;
    padding: 10px 16px;
  }
  .btn-direction-1 .btn,
  .btn-share-1 .btn {
    width: 100%;
    margin: 11px 0px;
    padding: 10px 0px;
  }
  .get-directions,
  .share-btn-chruch {
    margin: 0px 10px;
    width: 46%;
  }
  .watch-live-btn .btn {
    background: #491a40;
    color: #fafbfd;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
    border-radius: 49px;
    width: 94%;
    margin: 17px 13px;
  }
  .message_week h5 {
    font-size: 34px;
    width: 94%;
    text-align: center;
    margin: 0 auto;
  }
  .message_week p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 92%;
    margin: 19px auto;
    text-align: center;
  }

  .family-section h1 {
    font-size: 28px;
    text-align: center;
    line-height: 111%;

    margin: 10px 0 0 0;
  }
  .family-section h2 {
    font-size: 36px;
    line-height: 111%;
    text-align: center;
    margin: 0;
    /* opacity: 0.5; */
    border-bottom: none;
    padding-bottom: 23px;
    color: #491a40;
  }
  .outreach-section h3 {
    font-size: 24px;
    line-height: 31px;

    margin: 0px 0px 0px 12px;
  }
  #reach-out {
    max-width: 100%;
    height: 23px;
    margin: 5px 0;
  }
  .tv-outreach-section h5 {
    font-size: 20px;
    line-height: 26px;
    margin: 11px 0;
  }
  .family-section {
    margin: 45px 0;
  }
  .members-details {
    margin-left: 11%;
  }
  .man-of-god-btn-section {
    display: block;
  }
  .ministry-section img {
    max-width: 100%;
    height: 36px;
    margin-right: 10px;
    margin-top: -6px;
  }

  .ministry-section h4 {
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    margin: 0 auto;
  }
  .man-of-god-header {
    margin: -25px 0 22px 0 !important;
  }
  .tv-program h4 {
    font-size: 44px;
    text-align: center;
    margin: 5px 0;
  }
  .tv-program h4 span {
    font-size: 22px;
    line-height: 27px;
  }

  .helping-the-poor-section h3 {
    font-size: 23px;
    line-height: 112%;
    width: 320px;
  }
  .tv-program h5 {
    font-size: 22px;
    text-align: center;
    margin: 0 auto;
  }
  .tv-program p {
    font-size: 14px;
    line-height: 20px;
    color: #75809c;
    text-align: center;
    margin: 18px 0 31px;
  }
  #tv-2 {
    display: block;
    margin: 0px -15px;
    /* max-width: 100%; */
    width: 108%;
  }
  #tv-1 {
    display: none;
  }
  .tv-programs-img {
    margin: 32px 0px 0 0;
  }
  .tv-outreach-section p {
    font-size: 14px;
    line-height: 17px;

    width: 89%;
  }

  .helping-the-poor-section {
    margin: 38px 0 22px 0;
  }

  .testimonies_header {
    width: 100% !important;
    height: 100%;
    background: #ffffff;
    border: 0.5px solid #edf1fa;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
    border-radius: 15px;
  }
  .inspired_testimonties {
    padding: 52px 0 0px 0;
    margin-bottom: -40%;
  }
  .inspired_testimonties p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 96%;
  }
  .test-1 {
    display: none;
  }
  .chruch-header h4 {
    font-size: 24px;
    line-height: 100%;

    margin: 13px 0;
  }

  .weekly-timing-dates h6,
  .weekly-timing-dates_1 h6,
  .weekly-timing-dates-services-1 h6 {
    font-size: 12px;
    line-height: 17px;
    width: max-content;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates h4,
  .weekly-timing-dates-services-1 h4 {
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates_1 h4 {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    color: #fafbfd !important;
  }
  .subscribe_social_section h4 {
    font-size: 28px;
  }
  .subscribe_social_section p {
    font-size: 14px;
    line-height: 28px;
    margin: 10px;
  }
  .btn-tv-sponsor .btn,
  .btn-tv-View {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 100%;
  }

  .this-week-message-section {
    padding: 28px 0 0 0;
  }
  .video-tag {
    max-width: 100%;
    max-height: 103%;
    margin: 28px 0 0 0px;
  }
  /* home-page-css */

  /* TV-PROGRAMS CSS */
  .largest-tv-section h4 {
    font-size: 32px;
    line-height: 110%;
  }
  .largest-chruch-section-1 {
    height: 550px;
    background: url("./assets/images/tv-1-1.png");
    background-size: cover;
    position: relative;
    background-blend-mode: multiply, normal;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    border-top: none !important;
  }

  .program_btn {
    padding: 11px;
    text-align: center;
    background: #fdc332;
  }
  .program-btn-section {
    margin-top: 20px;
  }
  .largest-tv-section {
    text-align: center;
    padding: 48px 0 0px;
  }
  .largest-tv-section p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 39px;
    text-align: center;
    letter-spacing: -0.01em;
    display: initial;
    /* ColorWhite */

    color: #ffffff;
  }
  .tv-programs-sponsor-section {
    margin: 30px 0;
  }
  .tv-programs-sponsor-section h4 {
    font-family: "Inter";
    font-weight: 700;
    line-height: 34px;
  }

  .tv-programs-sponsor-section p {
    font-size: 16px;
    margin-bottom: 17px;
  }
  .proceed {
    padding: 13px 75px !important;
    width: 100%;
  }
  .form-control {
    padding: 15px 14px !important;
    border: 0.5px solid #d5ddf3 !important;
    border-radius: 8px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .form-group label {
    display: block !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin: 6px 0 9px 4px;

    color: #424b62;
  }

  select {
    background-position-x: 94% !important;
    background-position-y: 20px !important;
  }
  .form-control {
    height: calc(44px + 9px) !important;
  }
  .mt-top {
    margin-top: -36px;
  }
  .payment_bank_name h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .payment_bank_name h5 {
    font-size: 14px;
    margin: 10px 0px 7px 0px;
    font-weight: 500;
  }
  .online_payment-section {
    margin: 10px 19px !important;
    width: 89% !important;
  }
  .greeting_card_details {
    padding: 22px 0 0 14px;
    width: 63%;
  }
  .greeting_card_btn .btn {
    font-size: 10px;
    margin: 4px 0 0;
    width: 86%;
  }
  .back_to_btn {
    padding: 10px 0 !important;
    margin: 0 !important;
    width: 100%;
  }
  .back-span {
    border-bottom: 0.3px solid #bfd1e3;
    padding-left: -107px;
    border-top: none;
    margin: 9px -17px 29px -18px;
    padding-bottom: 4px;
    display: block;
  }
  .scan_qr-1 .btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    background: #3787ff;
    border-radius: 75px;
    color: #ffffff;
    margin: 19px 0 0 0;
  }
  .back-span-1 {
    border-bottom: 0.3px solid #bfd1e3;
    padding-left: -107px;
    border-top: none;
    margin: 0px -17px 29px -18px;
    padding-bottom: 4px;
    display: block;
  }
  #tv-ministry-scan {
    margin-bottom: 20px;
  }
  .Scan-Code {
    display: none;
  }
  .web-view-tv-ministry {
    display: none;
  }
  .mobile-view-tv-ministry {
    display: block;
  }
  .sbi_img {
    width: 59px;
    height: 59px;
    margin: 0px 5px;
  }
  /* TV-PROGRAMS CSS */
  /* PRAYER_REQUEST  CSS */
  .text-option {
    /* margin-bottom: 27px!important; */
    margin-top: 0 !important;
  }
  .prayer_request_section {
    background-image: url("./assets/images/res-1.png");
    background-size: cover;
    position: relative;
    height: 61vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    max-width: 100%;
  }
  .pray_request_header_request h1 {
    font-size: 36px;
    line-height: 104%;
    margin: 17px 0;
    text-align: center;
    width: 100%;
  }
  .prayer_request_name {
    margin: 0;
  }
  .problem_selection_menu_section {
    margin: 45px 0 0 0;
  }
  .pray_request_header_request {
    padding: 8px 0;
    margin: 0;
  }
  .prayer_tower_section h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    width: 61%;
  }
  .prayer_languges h3 {
    font-size: 17px;
    line-height: 23px;
    color: #171c22;
  }
  .prayer_languges h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #5a657f;
    overflow: scroll;
    width: auto;
  }
  .select-request select {
    margin-top: 0px !important;
  }
  .navbar-request {
    display: none;
  }

  .t_1 {
    width: 31px;
    height: 44px;
  }
  .problem_selection h4 {
    font-size: 18px;
    line-height: 22px;
    width: 92%;
    justify-content: left;
    text-align: left;
    margin: 0;
  }
  .prayer_request_name .col-md-3 {
    margin: 8px 0;
  }
  .prayer-request-header h4 {
    font-size: 20px;
  }
  .back-btn {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin: 5px 0;
    font-weight: 600;
  }
  .prayer-request-header p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #75809c;
  }
  .request-prayer .btn {
    width: 100% !important;
    margin: 0 0 6px 0px;
  }
  .greeting_card_details_main {
    height: 173px;
  }
  .success_Img {
    width: 74px;
  }
  .thank_you-section h4 {
    font-size: 14px;
    line-height: 17px;
    margin: 1px 0 2px;
  }
  .thank_you-section p {
    font-size: 13px;
    line-height: 16px;

    margin: 16px 0 15px;
    color: #75809c;
  }
  .thank_you-section h6 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    background: #fff3e5;
    border: 0.3px solid #ffb65c;
    border-radius: 31px;
    color: #ff8d00;
    padding: 10px;
    margin: auto;
    width: 66%;
    padding: 10px 0;
  }
  .greeting_card_details p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-align: left;
    margin: 6px 8px 6px 1px;
  }
  .greeting_card_details h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
  }
  .back-prayer {
    margin: 20px 0 5px;
  }
  .prayer-mobile-screen {
    display: block;
  }
  .prayer-laptop-screen {
    display: none;
  }
  /* PRAYER_REQUEST  CSS */
  /* DONATE   CSS */
  .scan_qr_details h4 {
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: #0b0076;
    width: 95%;
    margin: 10px auto 0px;
  }
  .another_payment_btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 26px;
    text-align: center;
    border-radius: 26px;
    margin: 7px 19px;
    width: 86%;
  }
  .account_details_top {
    margin-top: -7%;
  }
  .select_donation_section {
    background-image: url("./assets/images/d-1-1.png");
    background-size: cover;
    position: relative;
    height: 433px;
    max-width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
  }
  .card_donate h5 {
    font-size: 28px !important;
    line-height: 37px !important;
    text-align: left !important;
    color: #491a40 !important;
  }

  .payment_select_cards_section {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 440px;
    z-index: 1029;
  }
  .btn_option_single {
    display: grid;
    grid-template-columns: 168px 114px !important;
    grid-gap: 13px;
    gap: 13px;
  }
  .btn_multiple_option {
    display: grid;
    grid-template-columns: repeat(3, 91px) !important;
    grid-gap: 11px;
    gap: 11px;
  }
  .select_payment_option h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
  }
  .payment_section_details h3 {
    font-size: 32px;
    line-height: 34px;
    font-weight: 900;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    width: 84%;
    top: -65%;
    position: absolute;
    left: 10%;
  }

  /* DONATE CSS */
  .toggle_btns p {
    font-size: 15px !important;
  }
  .toggle_btns {
    border-radius: 10px !important;
  }
  .faq-header h1 {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 25px;
    margin-top: 5px;
  }
  .q-m {
    height: 61px;
    width: 74px;
  }
  mat-panel-title {
    font-size: 16px;
  }
  mat-expansion-panel p {
    font-size: 13px;
  }

  /* about-us  */
  .subscribe-section {
    height: 211px;
  }
  .about-us-header h3,
  .about-us-header h4 {
    font-size: 36px;
  }
  .about-us-title p {
    font-size: 15px;
    line-height: 28px;
  }
  .about-us-title h5 {
    font-size: 32px;
    line-height: 18px;
  }
  .about-us-title h4 {
    font-size: 22px;
    line-height: 18px;
  }
  .subscribe-title h4 {
    font-size: 21px;
    line-height: 10px;
    letter-spacing: 0.1px;
  }
  .btn-subscribe {
    font-size: 11px;

    padding: 6px 14px;
    margin-left: 5px;
  }
  .subscribe-form-section input {
    font-size: 12px;
  }
  .subscribe-form-section {
    width: 319px;
  }
  .fa-whatsapp:before {
    content: "\f232";
    font-size: 14px;
  }
  .submit-button button {
    margin: 0 0 23px 3px;
  }
  .title-img {
    height: 100%;
    max-width: 104%;
  }
  /* about-us  */
}
@media only screen and (min-width: 481px) and (max-width: 576px) {
  /* navbar-css */
  .prayer-mobile-screen {
    display: block;
  }
  .prayer-laptop-screen {
    display: none;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none !important;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("../src/assets/images/toggle.svg");
  }
  .main-header-section {
    display: none !important;
  }
  .main-header {
    background: #491a40;
    padding: 11px;
    margin: 59px 0 0 0;
  }
  /* navbar-css */
  /* home-page-css */

  .welcome_chruch h4 {
    font-size: 41px;
  }
  .welcome_chruch p {
    font-size: 16px;
    line-height: 19px;

    margin: 18px;
  }
  .join-btn-section .btn {
    font-size: 15.8px;
  }
  .prayers {
    height: 74vh;
  }
  .founder-details-section {
    margin: 0;
  }
  .founder-details-section h1 {
    font-size: 15px;
    text-align: center;
    line-height: 16px;
    margin: 51px 0 18px 0;
  }
  .founder-details-section h4 {
    font-size: 22px;
    text-align: center;
    line-height: 111.5%;
  }

  .founder-details-section h5 {
    font-size: 29px;
    text-align: center;
    line-height: 36px;
  }
  .founder-details-section h3 {
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  .founder-details-section p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    /* width: 60%; */
    /* justify-content: center; */
    /* display: flex; */
    margin: 23px auto;
  }
  .watch-btn .btn {
    padding: 13px 54px;
    width: 73%;
    justify-content: center;
    display: flex;
    margin: 13px auto;
  }
  .image-god {
    width: 109%;
  }

  .message_week {
    margin-top: 13px;
  }

  .chruch-temple-section h4 {
    display: none;
  }
  .family-section h4 {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #75809c;
  }
  .message_week h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 14px 0;
    text-align: center;
  }
  .chruch-buttons-section,
  .alternative_payments_btn {
    display: block;
  }
  .msg_btns_2,
  .chruch_btn_2 {
    display: flex;
  }

  .btn-watch {
    width: 97%;
    margin: 12px 10px !important;
    background: #fd6a65 !important;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
  }

  .btn-direction,
  .btn-share {
    color: #2b3244;
    background: #ffffff;
    border: 0.5px solid #2b3244;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
    border-radius: 49px;
    width: 99%;
    margin: 3px;
    padding: 10px 16px;
  }
  .btn-direction-1 .btn,
  .btn-share-1 .btn {
    width: 100%;
    margin: 11px 0px;
    padding: 10px 0px;
  }
  .get-directions,
  .share-btn-chruch {
    margin: 0px 10px;
    width: 46%;
  }
  .watch-live-btn .btn {
    background: #491a40;
    color: #fafbfd;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
    border-radius: 49px;
    width: 94%;
    margin: 14px 13px;
  }
  .message_week h5 {
    font-size: 34px;
    width: 94%;
    text-align: center;

    margin: 0 auto;
  }
  .message_week p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .family-section h1 {
    font-size: 28px;
    text-align: center;
    line-height: 111%;

    margin: 10px 0 0 0;
  }
  .family-section h2 {
    font-size: 36px;
    line-height: 111%;
    text-align: center;
    margin: 0;
    /* opacity: 0.5; */
    border-bottom: none;
    padding-bottom: 23px;
    color: #491a40;
  }
  .outreach-section h3 {
    font-size: 24px;
    line-height: 31px;

    margin: 0px 0px 0px 12px;
  }
  #reach-out {
    max-width: 100%;
    height: 23px;
    margin: 5px 0;
  }
  .tv-outreach-section h5 {
    font-size: 20px;
    line-height: 26px;
    margin: 11px 0;
  }
  .family-section {
    margin: 45px 0;
  }
  .members-details {
    margin-left: 11%;
  }
  .man-of-god-btn-section {
    display: block;
  }
  .ministry-section img {
    max-width: 100%;
    height: 35px;
    margin-right: 10px;
  }

  .ministry-section h4 {
    font-size: 28px;
    line-height: 100%;
  }
  .tv-program h4 {
    font-size: 44px;
    text-align: center;
    margin: 5px 0;
  }
  .tv-program h4 span {
    font-size: 22px;
    line-height: 27px;
  }

  .helping-the-poor-section h3 {
    font-size: 23px;
    line-height: 112%;

    width: 60%;
  }
  .tv-program h5 {
    font-size: 22px;
    text-align: center;
    margin: 0;
  }
  .tv-program p {
    font-size: 14px;
    line-height: 20px;
    color: #75809c;
    text-align: center;
    margin: 18px 0;
  }
  #tv-2 {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  #tv-1 {
    display: none;
  }
  .tv-programs-img {
    margin: 32px 0px 0 0;
  }
  .tv-outreach-section p {
    font-size: 14px;
    line-height: 17px;

    width: 89%;
  }

  .helping-the-poor-section {
    margin: 38px 0 22px 0;
  }

  .testimonies_header {
    width: 100% !important;
    height: 100%;

    background: #ffffff;
    border: 0.5px solid #edf1fa;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
    border-radius: 15px;
  }
  .inspired_testimonties {
    padding: 52px 0 0px 0;
    margin-bottom: -32%;
  }
  .inspired_testimonties p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    width: 96%;
  }
  .test-1 {
    display: none;
  }
  .chruch-header h4 {
    font-size: 24px;
    line-height: 100%;

    margin: 13px 0;
  }

  .weekly-timing-dates h6,
  .weekly-timing-dates_1 h6,
  .weekly-timing-dates-services-1 h6 {
    font-size: 12px;
    line-height: 17px;
    width: max-content;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates h4,
  .weekly-timing-dates-services-1 h4 {
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates_1 h4 {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    color: #fafbfd !important;
  }
  .subscribe_social_section h4 {
    font-size: 28px;
  }
  .subscribe_social_section p {
    font-size: 14px;
    line-height: 28px;
    margin: 10px;
  }
  .btn-tv-sponsor .btn,
  .btn-tv-View {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 100%;
  }

  .this-week-message-section {
    padding: 30px 0;
  }
  .video-tag {
    max-width: 100%;
    max-height: 103%;
    margin: 28px 0 0 0px;
  }
  /* home-page-css */
  /* Donate-css */
  .scan_qr_details h4 {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 800;
    font-size: 43px;
    line-height: 47px;
    text-align: center;
    margin: 10px 0 30px 0;
    color: #0b0076;
    width: 100%;
  }
  /* .another_payment_btn {
    
    margin: 5px 13px 0 24px;
   
} */
  .another_payment_btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 26px;
    text-align: center;
    border-radius: 26px;
    margin: 7px 19px;
    width: 86%;
  }
  .card_donate h5 {
    font-size: 28px !important;
    line-height: 37px !important;
    text-align: left !important;
    color: #491a40 !important;
  }
  .payment_section_details h3 {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: #491a40;
  }

  .payment_select_cards_section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn_option_single {
    display: grid;
    grid-template-columns: 168px 114px !important;
    grid-gap: 13px;
    gap: 13px;
  }
  .btn_multiple_option {
    display: grid;
    grid-template-columns: repeat(3, 94px) !important;
    grid-gap: 11px;
    gap: 11px;
  }
  .toggle_btns {
    width: 94px !important;
  }
  .btn-pray .btn,
  .select_payment_option h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
  }
  /* Donate-css */
  /*Tv-Programs-css */
  .largest-tv-section h4 {
    font-size: 36px;
    line-height: 110%;
  }
  .largest-tv-section {
    text-align: center;
    padding: 83px 0 0px;
  }
  .largest-chruch-section-1 {
    height: 58vh;
  }
  .program_btn {
    padding: 8px;
    text-align: center;
    background: #fdc332;
    border-radius: 68px;
  }

  /* PRAYER_REQUEST  CSS */

  .prayer_request_section {
    background-image: url("./assets/images/res-1.png");
    background-size: cover;
    position: relative;
    height: 495px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    max-width: 100%;
  }
  .pray_request_header_request h1 {
    font-size: 36px;
    line-height: 104%;
    margin: 18px 0;
    text-align: center;
  }

  .pray_request_header_request {
    padding: 8px 0;
  }
  .prayer_tower_section h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-align: left;
    width: 61%;
  }
  .prayer_languges h3 {
    font-size: 17px;
    line-height: 23px;
    color: #171c22;
  }
  .prayer_languges h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #5a657f;
  }
  .t_1 {
    width: 31px;
    height: 44px;
  }
  .problem_selection h4 {
    font-size: 22px;
    line-height: 24px;
    width: 92%;
    justify-content: left;
    text-align: left;
  }
  .prayer_request_name .col-md-3 {
    margin: 8px 0;
  }
  .prayer-request-header h4 {
    font-size: 20px;
  }
  .back-btn {
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    margin: 5px 0;
  }
  .prayer-request-header p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #75809c;
  }
  .request-prayer .btn {
    width: 100% !important;
    margin: 26px 0;
  }
  .success_Img {
    width: 74px;
  }
  .thank_you-section h4 {
    font-size: 16px;
    line-height: 6px;

    margin: 10px 0 6px;
  }
  .thank_you-section p {
    font-size: 13px;
    line-height: 16px;

    margin: 16px 0 15px;
    color: #75809c;
  }
  .thank_you-section h6 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    background: #fff3e5;
    border: 0.3px solid #ffb65c;
    border-radius: 31px;
    color: #ff8d00;
    padding: 10px;
    margin: auto;
    width: 66%;
    padding: 10px 0;
  }
  .greeting_card_details p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-align: left;
    margin: 6px 8px 6px 1px;
  }
  .greeting_card_details h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
  }
  .response_details_1 {
    width: auto !important;
  }
  .greeting_card_details {
    padding: 19px 0 0 16px;
    width: 47%;
  }
  .greeting_card_details_main {
    height: 182px;
  }
  .request-prayer .btn {
    width: 100% !important;
    margin: 0px 0 0 0;
  }
  ::ng-deep .mat-dialog-container {
    margin-top: 23% !important;
    padding: 6px 0 18px 0 !important;
  }
  /* PRAYER_REQUEST  CSS */
  /* Tv-Programs-css */

  /* .faq-header h1 {
    line-height: 32px;
    font-size: 23px;
    margin-top: 5px;
  }
  .subscribe-form-section {
    width: 419px;
  }
  .download-app-section h4 {
    font: normal 800 40px/108% "Red Hat Display";
  }
   .join-chruch-section h4 {
     
    font-size: 32px;
    
} */
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  /* navbar-css */
  .main-header-section {
    display: none !important;
  }
  .main-header {
    background: #491a40;
    padding: 11px;
    margin: 59px 0 0 0;
  }
  /* navbar-css */
  /* home-page-css */

  .welcome_chruch h4 {
    font-size: 41px;
  }
  .welcome_chruch p {
    font-size: 16px;
    line-height: 19px;

    margin: 18px;
  }
  .join-btn-section .btn {
    font-size: 15.8px;
  }
  .prayers {
    height: 74vh;
  }
  .founder-details-section {
    margin: 0;
  }
  .founder-details-section h1 {
    font-size: 15px;
    text-align: center;
    line-height: 16px;
    margin: 51px 0 18px 0;
  }
  .founder-details-section h4 {
    font-size: 22px;
    text-align: center;
    line-height: 111.5%;
  }
  .prayers {
    height: 74vh;
  }
  .founder-details-section h5 {
    font-size: 29px;
    text-align: center;
    line-height: 36px;
  }
  .founder-details-section h3 {
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  .founder-details-section p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 60%;
    /* justify-content: center; */
    /* display: flex; */
    margin: 23px auto;
  }
  .watch-btn .btn {
    padding: 13px 54px;

    width: 53%;
    justify-content: center;

    display: flex;
    margin: 13px auto;
  }
  .image-god {
    width: 109%;
  }

  .message_week {
    margin-top: 13px;
  }

  .chruch-temple-section h4 {
    display: block;
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 108%;
    color: #17161a;
    width: 50%;
    justify-content: center;
    margin: 0 auto 41px;
  }

  .family-section h4 {
    display: none;
  }
  .message_week h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 14px 0;
    text-align: center;
  }
  .chruch-buttons-section,
  .chruch-buttons-section {
    display: block;
  }
  .msg_btns_2,
  .chruch_btn_2 {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .btn-watch {
    width: 71%;
    margin: 12px 79px !important;
    background: #fa7a78;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
  }

  .btn-direction,
  .btn-share {
    color: #2b3244;
    background: #ffffff;
    border: 0.5px solid #2b3244;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
    border-radius: 49px;
    width: 100%;
    margin: 3px;
    padding: 10px 16px;
  }
  .btn-direction-1 .btn,
  .btn-share-1 .btn {
    width: 100%;
    margin: 11px 0px;
    padding: 10px 0px;
  }
  .get-directions,
  .share-btn-chruch {
    margin: 0px 10px;
    width: 33%;
  }
  .watch-live-btn .btn {
    background: #491a40;
    color: #fafbfd;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 7%);
    border-radius: 49px;
    width: 70%;
    margin: 14px auto;

    justify-content: center;
    display: flex;
  }
  .message_week h5 {
    font-size: 34px;

    text-align: center;
  }
  .message_week p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    text-align: center;
  }

  .family-section h1 {
    font-size: 28px;
    text-align: center;
    line-height: 111%;

    margin: 10px 0 0 0;
  }
  .flex-column-reverse {
    flex-direction: initial !important;
  }
  .family-section h2 {
    font-size: 36px;
    line-height: 111%;
    text-align: center;
    margin: 0;
    /* opacity: 0.5; */
    border-bottom: none;
    padding-bottom: 23px;
    color: #491a40;
  }
  .outreach-section h3 {
    font-size: 24px;
    line-height: 31px;

    margin: 0px 0px 0px 12px;
  }
  #reach-out {
    max-width: 100%;
    height: 23px;
    margin: 5px 0;
  }
  .tv-outreach-section h5 {
    font-size: 20px;
    line-height: 26px;
    margin: 11px 0;
  }
  .family-section {
    margin: 45px 0;
  }
  .members-details {
    margin-left: 11%;
  }
  .man-of-god-btn-section {
    display: block;
  }
  .ministry-section img {
    max-width: 100%;
    height: 35px;
    margin-right: 10px;
  }

  .ministry-section h4 {
    font-size: 28px;
    line-height: 100%;
  }
  .tv-program h4 {
    font-size: 45px;
    text-align: center;
    margin: 5px 0;
  }
  .tv-program h4 span {
    font-size: 15px;
    line-height: 27px;
  }

  .helping-the-poor-section h3 {
    font-size: 39px;
    line-height: 112%;

    width: 60%;
  }
  .tv-program h5 {
    font-size: 39px;
    text-align: center;
    margin: 0;
  }
  .tv-program p {
    font-size: 14px;
    line-height: 19px;
    color: #75809c;
    text-align: center;
    margin: 18px 0;
  }
  #tv-2 {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  #tv-1 {
    display: none;
  }
  .tv-programs-img {
    margin: 3% -41% 0% -4%;
  }
  .tv-outreach-section p {
    font-size: 14px;
    line-height: 17px;

    width: 89%;
  }

  .helping-the-poor-section {
    margin: 38px 0 22px 0;
  }

  .testimonies_header {
    width: 100% !important;
    height: auto;
    background: #ffffff;
    border: 0.5px solid #edf1fa;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
    border-radius: 15px;
  }
  .inspired_testimonties {
    padding: 52px 0 0px 0;
    margin-bottom: -18%;
  }
  .inspired_testimonties p {
    font-size: 20px;
    line-height: 24px;
    width: 63%;
  }
  .chruch-header h4 {
    font-size: 24px;
    line-height: 100%;

    margin: 13px 0;
  }

  .weekly-timing-dates h6,
  .weekly-timing-dates_1 h6,
  .weekly-timing-dates-services-1 h6 {
    font-size: 12px;
    line-height: 17px;
    width: max-content;

    /* margin: 0 15px; */
  }
  .weekly-timing-dates h4,
  .weekly-timing-dates-services-1 h4 {
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates_1 h4 {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    color: #fafbfd !important;
  }
  .subscribe_social_section h4 {
    font-size: 28px;
  }
  .subscribe_social_section p {
    font-size: 14px;
    line-height: 28px;
    margin: 10px;
  }
  .btn-tv-sponsor .btn,
  .btn-tv-View {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 100%;
  }

  .this-week-message-section {
    padding: 30px 0;
  }
  .video-tag {
    max-width: 100%;
    max-height: 103%;
    margin: 28px 0 0 0px;
  }
  /* home-page-css */
  /*Tv-Programs-css */
  .largest-tv-section h4 {
    font-size: 42px;
    line-height: 110%;
  }
  .largest-tv-section {
    text-align: center;
    padding: 83px 0 0px;
  }
  .largest-chruch-section-1 {
    height: 58vh;
  }
  .program_btn {
    padding: 8px;
    text-align: center;
    background: #fdc332;
    border-radius: 68px;
  }
  /* Tv-Programs-css */

  /* PRAYER_REQUEST  CSS */
  .prayer_request_section {
    background-image: url("./assets/images/res-1.png");
    background-size: cover;
    position: relative;
    height: 61vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    max-width: 100%;
  }
  .pray_request_header_request h1 {
    font-size: 36px;
    line-height: 104%;

    text-align: center;
  }

  .pray_request_header_request {
    padding: 8px 0;
  }
  .prayer_tower_section h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-align: left;
    width: 100%;
  }
  .prayer_languges h3 {
    font-size: 20px;
    line-height: 23px;
    color: #171c22;
  }
  .prayer_languges h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #5a657f;
  }
  .t_1 {
    width: 31px;
    height: 44px;
  }
  .problem_selection h4 {
    font-size: 22px;
    line-height: 22px;
    width: 92%;
    justify-content: left;
    text-align: left;
  }
  .prayer_request_name .col-md-3 {
    margin: 8px 0;
  }
  .prayer-request-header h4 {
    font-size: 20px;
  }
  .back-btn {
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    margin: 5px 0;
  }
  .prayer-request-header p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #75809c;
  }
  .request-prayer .btn {
    width: 100% !important;
    margin: 0 0 13px 0px;
  }
  .greeting_card_details_main {
    height: 173px;
  }
  .success_Img {
    width: 74px;
  }
  .thank_you-section h4 {
    font-size: 14px;
    line-height: 17px;
    margin: 1px 0 2px;
  }
  .thank_you-section p {
    font-size: 13px;
    line-height: 16px;

    margin: 16px 0 15px;
    color: #75809c;
  }
  .thank_you-section h6 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    background: #fff3e5;
    border: 0.3px solid #ffb65c;
    border-radius: 31px;
    color: #ff8d00;
    padding: 10px;
    margin: auto;
    width: 66%;
    padding: 10px 0;
  }
  .greeting_card_details p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-align: left;
    margin: 6px 8px 6px 1px;
  }
  .greeting_card_details h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
  }
  .response_details_1 {
    width: auto !important;
  }
  .greeting_card_details {
    padding: 19px 0 0 16px;
    width: 47%;
  }
  /* PRAYER_REQUEST  CSS */

  /* Donate-css */
  .scan_qr_details h4 {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 800;
    font-size: 43px;
    line-height: 47px;
    text-align: center;
    margin: 10px 0 30px 0;
    color: #0b0076;
    width: 100%;
  }
  /* .another_payment_btn {
    
    margin: 5px 13px 0 24px;
   
} */
  .another_payment_btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 26px;
    text-align: center;
    border-radius: 26px;
    margin: 7px 19px;
    width: 86%;
  }
  .card_donate h5 {
    font-size: 28px !important;
    line-height: 37px !important;
    text-align: left !important;
    color: #491a40 !important;
  }
  .payment_section_details h3 {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: #491a40;
  }

  .payment_select_cards_section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn_option_single {
    grid-template-columns: 182px 128px !important;
  }
  .btn_multiple_option {
    grid-template-columns: repeat(3, 100px) !important;
  }
  .toggle_btns {
    width: 100px !important;
  }
  .alternative_payments_btn {
    display: block;
  }
  /* Donate-css */
  /* .faq-header h1 {
    line-height: 32px;
    font-size: 23px;
    margin-top: 5px;
  }
  .subscribe-form-section {
    width: 419px;
  }
  .download-app-section h4 {
    font: normal 800 40px/108% "Red Hat Display";
  }
   .join-chruch-section h4 {
     
    font-size: 32px;
    
} */

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .welcome_chruch h4 {
    font-size: 30px;
    width: 50%;
  }
  .welcome_chruch {
    margin: 0 auto;
    text-align: center;
    padding-top: 49%;
  }
  .card_img_request {
    max-width: 100%;
    /* width: 100%; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 83px;
  }
  .qr_img {
    clip-path: inset(40% 21% 36% 22%) !important;
    margin-top: -77% !important;
    width: 100% !important;
    height: auto !important;
    margin-bottom: -64% !important;
  }
  .slick-prev {
    position: absolute;
    top: -48%;
    left: 86%;
  }
  .slick-next {
    position: absolute;
    top: -37%;
    left: 93%;
  }
  /* prayer-req css*/
  .marriage_option h5 {
    font-size: 11px;
  }
  .pray_request_btns .btn {
    font-size: 11px;
  }
  /* prayer-req css*/
  /* home  css */

  .prayers {
    height: 60vh;
  }
  .main-header-section h4 {
    font-family: Inter;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    padding: 0px 14px;
    margin: 0;
  }
  .welcome_chruch p {
    font-size: 22px;
    line-height: 19px;

    margin: 23px;
  }
  .join-btn-section .btn {
    font-size: 15.8px;
  }
  .founder-details-section {
    margin: 0 0px 0px 6px;
  }
  .founder-details-section h1 {
    font-size: 13px;
    text-align: left;
    line-height: 16px;
    margin: 53px 0 6px 0;
  }
  .founder-details-section h4 {
    font-size: 22px;
    text-align: left;
    line-height: 111.5%;
  }
  .image-god {
    width: 177%;
  }
  .watch-btn .btn {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    padding: 8px 33px;
    margin: 0px 3px;
  }
  .btn-Schedule {
    padding: 8px 33px !important;

    width: 100%;
  }

  .founder-details-section h5 {
    font-size: 29px;
    text-align: left;
    line-height: 36px;
  }
  .founder-details-section h3 {
    font-size: 16px;
    line-height: 16px;
    text-align: left;
  }
  .founder-details-section p {
    font-size: 15px;
    line-height: 20px;

    text-align: left;
    margin: 18px 0px;
    width: auto;
  }
  .message_week {
    margin-top: 13px;
  }

  .chruch-temple-section h4 {
    display: block;

    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 108%;
    color: #17161a;
    text-align: center;
    /* width: 50%; */
    justify-content: center;
    margin: 11px auto 30px;
  }
  .family-section h4 {
    display: none;
  }
  .message_week h4 {
    font-weight: 500;
    font-size: 24px;
    margin: 14px 0;
    text-align: left;
  }
  .message_week h5 {
    font-size: 38px;

    text-align: left;
    width: 100%;
  }
  .message_week p {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: left;
  }
  .video-tag {
    max-width: 100%;
    max-height: 103%;
    margin: 19px 0 0 23px;
  }
  .timing-section-btns {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #fff;
    padding: 10px 21px;
    margin: 0 0 0 14px;
    width: max-content;
  }
  .btn-video {
    width: max-content;
    border-radius: 82px;
    padding: 10px 26px;
    margin: 2px 10px 0 0 !important;
  }
  .family-section h1 {
    font-size: 28px;
    text-align: left;
    line-height: 111%;

    margin: 10px 0 0 0;
  }
  .family-section h2 {
    font-size: 36px;
    line-height: 111%;
    text-align: left;
    margin: 0;

    color: #491a40;
  }
  .home-page-hr {
    opacity: 0.5;
    border: 0.5px solid #491a40;
    width: 93%;
    margin: 29px 0px;
  }
  .outreach-section h3 {
    font-size: 24px;
    line-height: 31px;

    margin: 0px 0px 0px 12px;
  }
  #reach-out {
    max-width: 100%;
    height: 23px;
    margin: 5px 0;
  }
  .tv-outreach-section h5 {
    font-size: 17px;
    line-height: 17px;
    margin: 16px 0 11px 0px;
  }
  .tv-outreach-section p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #232226;
    width: 100%;
    margin: 0;
  }

  .family-section {
    margin: 77px 0 0 21px;
  }
  .members-details {
    margin: 21px 0;
  }

  .ministry-section img {
    max-width: 100%;
    height: 35px;
    margin-right: 10px;
  }

  .ministry-section h4 {
    font-size: 31px;
    line-height: 100%;
  }
  .tv-program h4 {
    font-size: 52px;
    text-align: left;
    margin: 5px 0;
  }

  .tv-program h4 span {
    font-size: 22px;
    line-height: 27px;
  }

  .tv-program h5 {
    font-size: 35px;
    text-align: left;
    margin: 0;
  }
  .tv-program p {
    font-size: 13px;
    line-height: 20px;
    color: #75809c;
    text-align: left;
    margin: 18px 0;
  }

  #tv-1 {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .tv-programs-img {
    margin: -38% -32% 0% -17%;
  }

  .helping-the-poor-section h3 {
    font-size: 30px;
    line-height: 112%;
    width: 56%;
  }
  .tv-outreach-section p {
    font-size: 14px;
    line-height: 17px;

    width: 89%;
  }

  .helping-the-poor-section {
    margin: 38px 0 22px 0;
  }

  .testimonies_header {
    width: 100% !important;
    height: 100%;

    background: #ffffff;
    border: 0.5px solid #edf1fa;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
    border-radius: 15px;
  }
  .inspired_testimonties {
    padding: 52px 0 0px 0;
    margin-bottom: -12%;
  }
  .inspired_testimonties p {
    font-size: 20px;
    line-height: 32px;
    width: 100%;
  }
  .chruch-header h4 {
    font-size: 24px;
    line-height: 100%;

    margin: 13px 0;
  }
  .btn-direction,
  .btn-share {
    color: #2b3244;
    background: #ffffff;
    border: 0.5px solid #2b3244;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.07));
    border-radius: 49px;
  }
  .weekly-timing-dates h6,
  .weekly-timing-dates_1 h6,
  .weekly-timing-dates-services-1 h6 {
    font-size: 12px;
    line-height: 17px;
    width: max-content;

    /* margin: 0 15px; */
  }
  .weekly-timing-dates h4,
  .weekly-timing-dates-services-1 h4 {
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 15px; */
  }
  .weekly-timing-dates_1 h4 {
    font-size: 14px;
    line-height: 25px;
    margin: 0;
    color: #fafbfd !important;
  }
  .subscribe_social_section h4 {
    font-size: 28px;
  }
  .subscribe_social_section p {
    font-size: 14px;
    line-height: 28px;
    margin: 10px;
  }
  .btn-tv-sponsor .btn,
  .btn-tv-View {
    font-size: 12px;
    line-height: 11px;
    text-align: center;
    width: 100%;
  }

  /* home css */
  /* tv-programs css */
  .form-group-1 label {
    margin: -2px 30px 6px 11px !important;
  }
  .form-group-1 .form-control {
    margin: 4px 7px !important;
  }
  .largest-tv-section {
    text-align: center;
    padding: 107px 0 0px;
  }
  /* .largest-chruch-section-1 {
  height: 42vh;
} */
  /* tv-programs css */
  /* Donate css */
  .scan_qr_details h4 {
    font-size: 38px;
    line-height: 46px;

    margin: 43px 0 9px 0;

    width: 100%;
  }
  .btn_another {
    padding: 11px 13px;
    width: 64%;
  }
  .btn_learn {
    width: 50%;
    color: #2b3244;
    border: 0.5px solid #2b3244;
    padding: 13px 10px;
    background: transparent;
  }
  .payment_section_details h3 {
    font-size: 55px;
    line-height: 60px;
  }
  .card_donate h5 {
    font-size: 28px;
    line-height: 20px;
  }

  .payment_select_cards_section {
    position: absolute;
    top: 51%;
    left: 64%;
    transform: translate(-50%, -50%);
  }

  /* Donate css */

  /* PRAYER_REQUEST  CSS */

  .pray_request_header_request h1 {
    font-size: 36px;
    line-height: 104%;
    margin: 18px 0;
    text-align: center;
  }

  .pray_request_header_request {
    padding: 8px 0;
  }
  .prayer_tower_section h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    text-align: left;
    width: 61%;
  }
  .prayer_languges h3 {
    font-size: 18px;
    line-height: 23px;
    color: #171c22;
    text-align: left;
    margin: -6px 12px 10px 10px;
    font-weight: 600;
  }
  .prayer_languges h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #5a657f;
  }
  .t_1 {
    width: 31px;
    height: 44px;
  }
  .problem_selection h4 {
    font-size: 22px;
    line-height: 24px;
    width: auto;
    text-align: center;
    justify-content: center;
  }
  .prayer_request_name .col-md-3 {
    margin: 8px 0;
  }
  .response_details_1 {
    width: 517px !important;
  }
  .prayer-request-header h4 {
    font-size: 20px;
  }
  .back-btn {
    font-size: 16px;
    line-height: 22px;
    text-align: center;

    margin: 5px 0;
  }
  .prayer-request-header p {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #75809c;
  }

  .success_Img {
    width: 74px;
  }
  .thank_you-section h4 {
    font-size: 16px;
    line-height: 6px;

    margin: 10px 0 6px;
  }
  .thank_you-section p {
    font-size: 13px;
    line-height: 16px;

    margin: 16px 0 15px;
    color: #75809c;
  }
  .thank_you-section h6 {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    background: #fff3e5;
    border: 0.3px solid #ffb65c;
    border-radius: 31px;
    color: #ff8d00;
    padding: 10px;
    margin: auto;
    width: 40%;
    padding: 10px 0;
  }
  .greeting_card_details p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-align: left;
    margin: 6px 8px 6px 1px;
  }
  .greeting_card_details_main {
    background-size: cover;
    position: relative;
    height: 195px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center;
    max-width: 100%;
    border-radius: 20px;
  }
  .greeting_card_details h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 19px;
    color: #ffffff;
    text-align: left;
  }
  /* PRAYER_REQUEST  CSS */
}

@media (min-width: 992px) and (max-width: 1280px) {
  .welcome_chruch {
    margin: 0 auto;
    text-align: center;
    padding-top: 36%;
  }
  .welcome_chruch h4 {
    width: 74%;
  }
  /* home-page-css  */
  .slick-next {
    position: absolute;
    top: -37%;
  }
  .slick-prev {
    position: absolute;
    top: -48%;
    left: 88%;
  }
  .slick-prev-1 {
    position: absolute;
    top: -17%;
    left: 88%;
  }
  .family-section {
    margin-top: 122px;
    margin-left: 44px;
  }
  #tv-2 {
    display: none;
  }
  .family-section h2 {
    font-size: 49px;
    line-height: 99.5%;
  }
  .home-page-hr {
    opacity: 0.5;
    border: 0.5px solid #491a40;
    width: 89%;
    margin: 29px 0px;
  }
  /* home-page-css  */
  /* prayer-request-css  */
  .pray_request_header_request {
    padding: 150px 0 0 0;
    margin-left: 0;
  }
  /* prayer-request-css */
  /* tv-program-css  */
  .largest-tv-section {
    text-align: center;
    padding: 98px 0 0px;
  }
  /* tv-program-css  */

  .faq-header h1 {
    font-size: 22px;
    line-height: 29px;
    margin-top: 8px;
  }
  .download-app-section h4 {
    font: normal 800 40px/108% "Red Hat Display" !important;
  }

  .q-m {
    max-width: 96px;
  }
  .subscribe-form-section {
    width: 419px;
  }

  .founder-details-section h1 {
    font-size: 16px;
  }
  .founder-details-section h4 {
    font-size: 30px;
  }
  .founder-details-section h5 {
    font-size: 38px;
    margin: 0px 0px 8px 0;
  }
  .founder-details-section span {
    font-size: 14px;
  }
  .founder-details-section p {
    font-size: 15px;
    margin: 26px 0;
    line-height: 26px;
    width: 100%;
  }
  .founder-details-section {
    margin: 33px 0px 38px -108px;
  }
  .image-god {
    margin-top: 44px;
  }
  .watch-btn .btn {
    font-size: 13px;
    padding: 9px 44px;
    margin: 5px 9px;
  }
  .message_week h4 {
    font-size: 20px;
  }
  .btn-video {
    width: max-content !important;
    border-radius: 82px;
    padding: 10px 26px !important;
    margin: 0px 0px 0 0 !important;
  }
  .timing-section-btns {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #fff;
    padding: 10px 21px;
    margin: 0 0 0 14px;
    width: max-content;
  }
  /* .timing-section-btns {
    padding: 8px 24px !important;
    margin: 17px 20px 0 0 !important;
  } */
  /* .btn-video {
    margin: 12px 5px !important;
  } */
  .video-tag {
    margin: 62px 0 0 30px;
  }
  .message_week h5 {
    font-size: 50px;
  }
  .message_week p {
    font-size: 16px;
    line-height: 25px;
  }
  /* .family-section h1 {
    font-size: 34px;
    width: 90%;
    margin: 20px 0;
    border-bottom: 1px solid #491a40;
    padding-bottom: 23px;
  } */
  #reach-out {
    height: 21px;
  }
  .outreach-section h3 {
    font-size: 34px;

    margin: 2px 0px 0px 6px;
  }
  .btn-direction,
  .btn-share {
    color: #2b3244;
  }
  .tv-outreach-section h5 {
    font-size: 22px;
    line-height: 24px;
    margin: 5px 0;
  }

  .tv-outreach-section p {
    font-size: 14px;
    width: 84%;
  }
  .tv-program p {
    font-size: 16px;
    line-height: 29px;
  }
  .tv-program h5 {
    font-size: 54px;
    width: 100%;
  }
  .tv-program h4 span {
    font-size: 24px;
  }

  .ministry-section h4 {
    font-size: 43px;
  }
  .ministry-section img {
    height: 48px;
  }
  .tv-programs-img {
    margin: -35% -11% 0% -31%;
  }
  .tv-program h4 {
    font-size: 98px;
  }
  .btn-tv-sponsor .btn,
  .btn-tv-View {
    font-size: 13px;

    padding: 10px 13px;
  }
  .slider .slide-track-1 {
    margin-top: 14%;
  }
  /* Donate css */

  .scan_qr_details h4 {
    font-size: 47px;
    margin: 68px 0 30px 0;
    line-height: 46px;

    width: 100%;
  }

  .btn_another {
    padding: 17px 15px;
    width: 64%;
  }
  .btn_learn {
    width: 50%;
    color: #2b3244;
    border: 0.5px solid #2b3244;
    padding: 13px 10px;
    background: #0000;
  }
  .main-header-section h4 {
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    padding: 4px 15px;
    margin: 0;
  }
  .payment_section_details h3 {
    font-size: 57px;
    line-height: 60px;
  }
  .card_donate h5 {
    font-size: 28px;
    line-height: 20px;
  }

  .payment_select_cards_section {
    position: absolute;
    top: 51%;
    left: 64%;
    transform: translate(-50%, -50%);
  }

  /* Donate css */
  /* watch css */
}
/* media queries */
